import React, {useCallback, useEffect, useMemo} from "react";

import "./InletBar.scss";
import {Alert, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import TestCaseWizard from "../TestCaseWizzard/TestCaseWizard";
import {UpwireModalError, UpwireModalLoading, useUpwireModal} from "../../common/modal/modal";
import {
    PlaygroundRun,
    RunCondition,
    RuntimeSeries,
    Status,
    Step,
    usePlaygroundDispatchApi
} from "../../../api/playground/dispatch";
import {useMutation, useQuery} from "@tanstack/react-query";
import classNames from "classnames";
import {fromNow} from "../../../../util/datetime";
import {createPlaygroundInfo} from "../kibitzer/playground";
import {PlaygroundRunKibitzer} from "../kibitzer/kibitzer";
import {RefreshRounded} from "@mui/icons-material";
import {raiseGlobalError} from "../../../../core/dispatch/errors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useHashDisplay} from "../../../../util/strings";
import {ApiWizard} from "../TestCaseWizzard/ApiWizard";

import {DropOptions, DropOptionsProps} from "../../common/Droptions/droptions";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DataObjectIcon from "@mui/icons-material/DataObject";
import {TestCase} from "../../common/testCase";
import {useVarContextApi} from "../../../../components/VarContext/data";
import {usePlayground} from "../../../containers/playground/PlaygroundContext";

let globalRSCounter = 0;

function getRuntimeSeriesFromPlaygroundRun(run: PlaygroundRun | undefined): RuntimeSeries | null {

    if (run === undefined)
        return null;

    if (run === null)
        return null;

    run = JSON.parse(JSON.stringify(run)) as PlaygroundRun;

    const runSteps = new Map<string, Step>();
    const stepReferencesFromTo = new Map<string, string[]>();

    const conditions: RunCondition[] = [];

    function registerStep(step: Step) {
        const stepId = step.id;
        const fromStepId = step.from_step_id;

        if (!stepReferencesFromTo.has(fromStepId))
            stepReferencesFromTo.set(fromStepId, []);
        stepReferencesFromTo.get(fromStepId)?.push(stepId);
        runSteps.set(stepId, step);
    }

    for (const canvas of run.canvas_runs || []) {

        const condition: RunCondition = {
            canvas: canvas,
            events: [],
            overallStatus: canvas.status,
        };

        const events = condition.events;
        conditions.push(condition);

        const phaseRuns = canvas.phase_runs || [];
        for (const phase of phaseRuns) {
            const nodes = phase.nodes || [];
            for (const node of nodes) {
                const steps = node.steps || [];
                for (const step of steps) {

                    if (!(step?.debug?.attempts?.length))
                        continue;

                    registerStep(step);

                    for (let i = 0; i < step.debug.attempts.length; i++) {
                        const attempt = step.debug.attempts[i];
                        const isLast = i === step.debug.attempts.length - 1;
                        if (!attempt.debug.logs)
                            attempt.debug.logs = [];
                        attempt.debug.logs.reverse();
                        events.push({
                            attempt,
                            step,
                            node,
                            phase,
                            canvas,
                            run,
                            status: isLast ? step.status : "FAILED",
                            caused: [],
                            causedBy: "start",
                        });
                    }
                }
            }
        }
    }


    for (const condition of conditions) {
        const events = condition.events;

        for (const event of events) {
            const stepStart = new Date(event.step.run_at).getTime();
            event.attempt.debug.logs = event.attempt.debug.logs.filter(log => new Date(log.created_on).getTime() >= stepStart);
        }

        for (const event of events) {
            const stepReferences = stepReferencesFromTo.get(event.step.id) || [];
            for (const stepReference of stepReferences) {

                const step = runSteps.get(stepReference);
                if (!step)
                    continue;

                for (const causedEvent of events.filter(e => e.step.id === step.id)) {
                    event.caused.push(causedEvent);
                    causedEvent.causedBy = event;
                }
            }
        }

        condition.events = events.sort((a, b) => {
            return new Date(a.attempt.debug["@attempted_on"]).getTime() - new Date(b.attempt.debug["@attempted_on"]).getTime();
        });
    }


    return {
        run,
        conditions: conditions,
        overallStatus: run.status
    };
}

function keepPolling(run: PlaygroundRun | undefined) {
    if (!run)
        return true;

    return run.status === "RUNNING" || run.status === "PENDING";
}

function PlaygroundRunKibitzerView({playgroundProps, runId}: { playgroundProps: any, runId: string }) {

    const playgroundApi = usePlaygroundDispatchApi();
    const query = ["playground", "run", runId];

    const {
        data: playgroundRun,
        isLoading: runLoading,
        error: runError,
    } = useQuery(query, async () => {
        try {
            return await playgroundApi.getRuntimeSeries(runId);
        } catch (e) {
            raiseGlobalError("Failed to fetch run info");
            throw e;
        }

    }, {
        refetchInterval: (run) => keepPolling(run) ? 2000 : false,
    });

    const runtimeSeries = useMemo(() => getRuntimeSeriesFromPlaygroundRun(playgroundRun), [playgroundRun]);

    if (runError) {
        const error = runError as Error;
        return <Alert severity="error">{error.toString()}</Alert>;
    }

    if (runLoading) {
        return <UpwireModalLoading message="Loading Test Run Details..."/>;
    }

    if (!runtimeSeries) {
        return <UpwireModalError message="Error getting run details data"/>;
    }

    const playground = createPlaygroundInfo(playgroundProps);
    return <PlaygroundRunKibitzer playground={playground} runtime={runtimeSeries}/>;
}

function PlaygroundRunKibitzerModal({
                                        runId,
                                        playgroundProps,
                                        onClose
                                    }: { runId: string, playgroundProps: any, onClose: () => void }) {

    const {modal, show} = useUpwireModal(<PlaygroundRunKibitzerView playgroundProps={playgroundProps} runId={runId}/>, {
        title: <><span>Test Run</span><i>{runId}</i></>,
        wide: true,
        onClose: onClose
    });

    useEffect(() => {
        show();
    });

    return modal;
}

function getRunDetails(run: PlaygroundRun) {

    function shortStatus(status: Status) {
        switch (status) {
            case "PENDING":
                return "...";
            case "RUNNING":
                return "RUN";
            case "COMPLETED":
                return "OK";
            case "FAILED":
                return "FAIL";
            case "ENDED":
                return "STOP";
            case "NOOP":
                return "NOOP";
            case "QUEUED":
                return "QUEUE";
            case "DEFERRED":
                return "DEFER";
            case "RETRY":
                return "RETRY";
            case "TIMED_OUT":
                return "TIMED-OUT";
            case "CANCELLED":
                return "CANCEL";
        }
    }

    const status = run.status.toLowerCase();
    const timeAgo = fromNow(run.created_on);
    const shortStatusText = shortStatus(run.status);

    return {
        status, timeAgo, shortStatusText
    };
}

type TestCaseTooltipProps = {
    run: PlaygroundRun
    onClick: () => void
}

function TestCaseTooltip(props: TestCaseTooltipProps) {

    const {run, onClick} = props;
    const {shortStatusText, timeAgo, status} = getRunDetails(run);

    const runDisplay = useHashDisplay(run.id);

    return <Tooltip
        arrow
        title={
            <>
                <div className="tooltip-row">{`RUN ${runDisplay}`}</div>
            </>
        } key={run.id}>

        <div onClick={onClick}
             className={classNames("latest-run-item", "status-name", status)}>
            <strong>{shortStatusText}</strong> {timeAgo}
        </div>
    </Tooltip>;
}

function LatestRunsView({playgroundId, playgroundProps}: { playgroundId: string, playgroundProps: any }) {
    const playgroundApi = usePlaygroundDispatchApi();

    const {
        data: runs,
        isLoading: runsLoading,
        isFetching: runsFetching,
        refetch: refreshRuns
    } = useQuery(["playground", playgroundId, "runs"], async () => {
        return await playgroundApi.getRuns(playgroundId, 10);
    });

    const [selectedRun, setSelectedRun] = React.useState<string | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const additionalMenuIsOpen = !!(anchorEl);

    useEffect(() => {
        setAnchorEl(null);
    }, [runsLoading, runsFetching]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const canRefresh = useCallback(() => {
        if (runsLoading || runsFetching)
            return false;

        return !selectedRun;
    }, [runsLoading, runsFetching, selectedRun]);

    useEffect(() => {
        const handler = setInterval(() => {

            if (!canRefresh())
                return;

            if (runs)
                for (let run of runs)
                    if (run.status === "RUNNING")
                        return refreshRuns();
        }, 15_000);

        return () => clearInterval(handler);
    }, [refreshRuns, runs, canRefresh]);

    useEffect(() => {
        const handler = setInterval(() => {
            if (canRefresh())
                return refreshRuns();
        }, 20_000);

        return () => clearInterval(handler);
    }, [canRefresh, refreshRuns]);


    if (runsLoading || runsFetching) {
        return <div className="latest-runs-list">
            <div className="latest-run-status">Updating...</div>
        </div>;
    }

    function selectRun(runId: string) {
        setSelectedRun(runId);
    }

    function closeRun() {
        setSelectedRun(null);
    }

    const refresh = <IconButton onClick={async () => {
        await refreshRuns();
    }}>
        <RefreshRounded/>
    </IconButton>;


    if (runs?.length) {

        const fastPreviewRuns = runs?.slice(0, 3);
        const moreRuns = runs?.slice(3);

        return <div className="latest-runs-list">
            {selectedRun &&
				<PlaygroundRunKibitzerModal runId={selectedRun} playgroundProps={playgroundProps} onClose={closeRun}/>}
            {fastPreviewRuns.map((run) => {
                return <TestCaseTooltip key={run.id} run={run}
                                        onClick={() => selectRun(run.id)}/>;
            })}
            {moreRuns.length > 0 &&
				<div>
					<IconButton
						aria-controls={additionalMenuIsOpen ? "basic-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={additionalMenuIsOpen ? "true" : undefined}
						onClick={handleClick}
						color="success"
					>
						<ExpandMoreIcon/>
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={additionalMenuIsOpen}
						onClose={handleClose}
						MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}

					>
                        {moreRuns?.map((run) => {
                            const {shortStatusText, timeAgo, status} = getRunDetails(run);
                            return <MenuItem
                                key={run.id}
                                sx={{
                                    margin: "5px", borderRadius: 1,
                                }}
                                dense={true}

                                onClick={() => {
                                    selectRun(run.id);
                                    handleClose();
                                }}
                                className={classNames("latest-run-item", "status-name", status)}
                            >
                                <strong>{shortStatusText}</strong>{timeAgo} ({"RUN " + run.id})
                            </MenuItem>;

                        })}

					</Menu>
				</div>
            }
            {refresh}
        </div>;
    }

    return <>
        <div className="latest-runs-list">
            <div className="latest-run-status">No recent tests...</div>
            {refresh}
        </div>
    </>;
}


export function InletBar(props: {
    playgroundProps: any,
    inlet: any
}) {

    const playgroundApi = usePlaygroundDispatchApi();
    const varContextApi = useVarContextApi();

    const playground = usePlayground();
    const [launchedRunId, setLaunchedRunId] = React.useState<string | null>(null);

    const {
        mutate: launch,
    } = useMutation(async ({testCase}: { testCase: TestCase }) => {

        const varContexts = await varContextApi.getVarContexts();
        const data = await playgroundApi.runTestCase(
            playground,
            testCase,
            varContexts
        );

        setLaunchedRunId(data.run_id);
    });

    const {
        modal: testCaseWizardModal,
        show: showTestCaseWizardModal,
        hide: hideTestCaseWizardModal
    } = useUpwireModal(<TestCaseWizard
        playground={playground}
        variables={playground.settings.variables}
        onRun={(testCase) => {
            hideTestCaseWizardModal();
            launch({
                testCase: testCase,
            });
        }}
    />, {
        title: "Run a Test Case",
        wide: true,
    });

    const {
        modal: apiExampleModal,
        show: showApiExampleModal,
    } = useUpwireModal(
        <ApiWizard/>, {
            title: "Run a Test Case Using API Call",
            wide: true
        }
    );

    const runModal = launchedRunId ?
        <PlaygroundRunKibitzerModal
            runId={launchedRunId}
            playgroundProps={props.playgroundProps}
            onClose={() => setLaunchedRunId(null)}/> : null;

    const dropOptions: DropOptionsProps = {
        text: "Run Playground",
        options: [
            {
                icon: <PlayCircleOutlineIcon/>,
                text: "Run a test case",
                onSelect: () => showTestCaseWizardModal()
            }, {
                icon: <DataObjectIcon/>,
                text: "Run test case with API call",
                onSelect: () => showApiExampleModal()
            }
        ]
    };

    return <>
        {runModal}
        {testCaseWizardModal}
        {apiExampleModal}
        <div className="pg-inlet-bar">
            <div className="start">
                <DropOptions {...dropOptions}/>
            </div>
            <div className="end">
                <LatestRunsView playgroundId={playground.playground_id} playgroundProps={props.playgroundProps}/>
            </div>
        </div>
    </>;
}
