import {raiseGlobalError} from "./errors";
import {environment} from "generated/env";

type ResponseData<T> = { ok: true; data: T } | { ok: false; message?: string; error?: string };

export class DispatchApi {

    constructor(protected readonly token: string | undefined | null, private readonly dispatchGroup: string, private readonly external: boolean = true) {

    }

    async dispatch<T = any>(action: string, payload: any = {}): Promise<T> {

        let response: ResponseData<T>;
        try {
            response = await this.fetch({action, ...payload});
        } catch (e: any) {
            console.error("Dispatch Error", action, payload, e);
            throw e;
        }

        if (response.ok)
            return response.data;

        console.error("Dispatch Error", action, payload, response);
        const title = <><strong>Dispatch API Error</strong> on <code>{this.getEndpoint()}.{action}()</code></>;
        raiseGlobalError(title, response.message ?? response.error ?? "Unknown API Error");
    }


    private getEndpoint(): string {
        if (this.external)
            return `${environment.external}/api/${this.dispatchGroup}`;
        return `/api/v2/${this.dispatchGroup}`;
    }

    private async fetch<T>(payload: any): Promise<ResponseData<T>> {

        if (!this.token) {
            return {ok: false, message: "Unauthorized"};
        }

        const endpoint = this.getEndpoint();
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.token}`,
                "X-UPG-UI": "web"
            },
            body: JSON.stringify(payload)
        });

        return await response.json();
    }
}
